// Dependencies
import React from "react"
// Hooks & Helpers
// ...
// Wrappers
import Layout from "../wrappers/Layout"
// Components
// ...

const NotFoundPage = () => (
  <Layout contactPage is404 />
)

export default NotFoundPage
